<template>
    <div class="footer">
        <div class="footer-body">
            <div class="footer-top">
                <a @click="goAbout('/privacy')">Privacy Policy</a>
                |
                <a @click="goAbout('/aboutus')">About Us</a>
                |
                <a @click="goAbout('/terms')">Terms of Use</a>
            </div>
            <div class="footer-mid"></div>
            <div class="footer-top">
                <a href="https://www.facebook.com/" target="_blank">Facebook</a>
                |
                <a href="https://www.twitter.com/" target="_blank">Twitter</a>
            </div>
            <div class="footer-bom">
                Copyright © 2024 &emsp; Email:lorengames@gmail.com
            </div>
        </div>
        <interstitialNd :id="id" v-if="isAD" @close="close"></interstitialNd>
    </div>
</template>

<script>
import interstitialNd from "@/components/adContent/interstitial2.vue";

export default {
    components:{
        interstitialNd
    },
    data(){
        return {
            isAD:false,
            id:''
        }
    },
    methods:{
        goAbout(path){
            this.id = path;
            this.isAD = true;
            console.log(path)
        },
        close(data){
            this.isAD = data;
        }
    }
}
</script>

<style lang="less" scoped>
.footer{
    background: #F04438;
    margin-top: 2em;
    padding: 2em 0;
    // position: fixed;
    // bottom: 0;
    // z-index: 999;
    // width: 100vw;
    .footer-body{
        width: 80%;
        margin: 0 auto;
        text-align: center;
        font-size: 12px;
        color: #FFF;
        .footer-top{
            margin: 10px 0;
        }
    }
    .footer-mid{
        width: 50%;
        margin: 0 auto 10px;
        border-top: 1px solid #eee
    }
}
a{
    color: #FFF;
    text-decoration: none;
    margin: 0 10px;
    &:hover{
        text-decoration: underline;
    }
}
</style>