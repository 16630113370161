<template>
    <div>
        <div class="cont-st cont-list">
            <div class="cont-st-h">
                <p>{{text}}</p>
            </div>
            <div class="cont-list-card" v-for="items in menuItem" :key="items.id" @click="goDetails(items)">
                <img :src="items.logo" alt="">
                <p class="no-warp">{{items.title}}</p>
            </div>
            <span v-for="item in spanNum" :key="item+'s'"></span>
        </div>
        <div class="class-info" v-if="intro">
            {{ intro }}
        </div>
    </div>
</template>

<script>
import eventBus from '@/utils/eventBus'
import { scrollToTop, isMobile } from '@/utils/api';
import { getGameByKey, searchGame } from '@/api/games';
import classInfo from "@/utils/classInfo"

    export default {
        data() {
            return {
                menuItem:[],
                spanNum:0,
                text:'',
                intro:''
            }
        },
        mounted() {
            if(isMobile()){
                this.spanNum = 3-2;
            }else{
                this.spanNum = 11-2;
            }

            scrollToTop(0);
            eventBus.$on('searchgame',name=>{
                this.menuItem = searchGame(name)
            })
            let {id,key} = this.$route.query
            this.text = key;
            this.getGameList(id,key);
        },
        methods: {
            goDetails(item){
                this.$router.push({
                    path:'/details',
                    query:{
                        id:item.id
                    }
                })
            },
            async getGameList(id,key){
                console.log(id,key);
                if(id=='search'){
                    this.intro = '';
                    this.menuItem = await searchGame({q:key});
                }else if(id=='more'){
                    this.intro = classInfo.find(item => item.name === key).intro;
                    this.menuItem = await getGameByKey({category:key});
                }else{
                    return
                }
                console.log(this.menuItem);
                
            }
        },
    }
</script>

<style lang="less" scoped>
.cont-st{
    padding: 0 20px;
    box-sizing: border-box;
    margin-bottom: 25px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .cont-st-h{
        width: 100%;
        height: 25px;
        margin-top: 14px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 18px;
        color: #1E1B4C;
        line-height: 25px;
        text-align: left;
        font-style: normal;
    }
}
.cont-list{
    // &::after{
    //     content: '   ';
    //     flex: auto;
    //     display: block;
    //     width: 100px;
    // }
    .cont-list-card{
        width: 100px;
        margin: 16px 0 0 0;
        img{
            width: 100px;
            height: 100px;
            border-radius: 15px;
            border: 2px solid #FFFFFF;
        }
        p{
            font-family: PingFangSC, PingFang SC;
            font-weight: 600;
            font-size: 14px;
            color: #1E1B4C;
            line-height: 20px;
            text-align: left;
            font-style: normal;
        }
    }
    span{
        width: 100px;
    }
}
.no-warp{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.class-info{
    padding: 8px 12px;
    box-sizing: border-box;
    border-radius: 10px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 16px;
    color: #000;
    line-height: 22px;
    text-align: left;
    font-style: normal;
}
</style>